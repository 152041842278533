import { Webchat, WebchatProvider, getClient } from "@botpress/webchat";
import { buildTheme } from "@botpress/webchat-generator";
import { useState, useEffect } from "react";
import './App.css';

const { theme, style } = buildTheme({
  themeName: "prism",
  themeColor: "#b3cfe9",
});

// Configuration object for the chatbot
const config = {
  composerPlaceholder: "Hi!",
  botName: "FIND",
  botAvatar: "/avatar.png",
  botDescription:
    "Designed to find and complete single specification and asterisk boxes for BQ_Codes.",
  privacyPolicy: {
    title: "Contact Rawan",
    link: "skype:live:.cid.233a3b1b05ef3c76?chat",
  },
  enableHistory: true,
};

const clientId = "02848801-638a-4a2a-b564-7b383e73d7d6";

export default function App() {
  const client = getClient({ clientId });
  const [isWebchatOpen, setIsWebchatOpen] = useState(true);

  useEffect(() => {



    const handleRestartConversation = () => {
      const webchatStore = client.store;
      if (webchatStore && webchatStore.restartConversation) {
        webchatStore.restartConversation();
      }
    };

    const targetElement = document.querySelector('.bpHeaderContentActionsContainer :nth-child(2)');
    if (targetElement) {
      const handleClick = () => {
        console.log("Clicked!");
        window.open("https://docs.google.com/document/d/1xQMJC_9n3ZyOZD16-rKLQi5pH0IFsKHV3BVwFNV5IO4/edit#heading=h.h63m7ydf9xfr", '_blank');
      };
  
      targetElement.addEventListener('click', handleClick);
  
      return () => {
        targetElement.removeEventListener('click', handleClick);
      };
    }

    
      const initializeWebChat = () => {
        if (window.botpressWebChat) {
          window.botpressWebChat.mergeConfig({ showConversationsButton: true });
        }
      };
    
      // Wait for the script to load completely before accessing botpressWebChat
      if (document.readyState === "complete") {
        console.log("complete");
        
        initializeWebChat();
      } else {
        window.addEventListener("load", initializeWebChat);
      }
    
      return () => {
        window.removeEventListener("load", initializeWebChat);
      };

    }, [client]); // Adding client to dependency array

  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      <style>{style}</style>

      <WebchatProvider
        key={JSON.stringify(config)}
        theme={theme}
        configuration={config}
        client={client}
      >
        <div
          className="text-red-500"
          style={{
            display: isWebchatOpen ? "block" : "none",
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            color: "red",
          }}
        >
          <Webchat />
        </div>
      </WebchatProvider>
    </div>
  );
}
